<template>
  <v-expansion-panel-content>
    <!-- host, port, user, password -->
    <v-row class="mt-5">
      <v-col cols="3">
        <mf-text-input v-model="server.connection.host" label="Host" :disabled="!editable"></mf-text-input>
      </v-col>
      <v-col cols="3">
        <mf-text-input v-model="server.connection.port" label="Porta" :disabled="!editable"></mf-text-input>
      </v-col>
      <v-col cols="3">
        <mf-text-input v-model="server.connection.user" label="Usuário" :disabled="!editable"></mf-text-input>
      </v-col>
      <v-col cols="3">
        <mf-text-input v-model="server.connection.password" label="Senha" :disabled="!editable"></mf-text-input>
      </v-col>
    </v-row>
    <v-row class="mt-n4">
      <v-col cols="3">
        <mf-text-input v-model="server.connection.database" label="Database" :disabled="!editable"></mf-text-input>
      </v-col>
      <v-col cols="3">
        <mf-text-input v-model="server.connection.charset" label="Charset" :disabled="!editable"></mf-text-input>
      </v-col>
      <v-col cols="3">
        <mf-text-input v-model="server.integrator" label="Integrator" disabled></mf-text-input>
      </v-col>
    </v-row>
    <!-- sqls, views, filters, variables -->
    <v-row>
      <v-col v-if="server.integrator === 'database_mongo_integrator'" cols="3">
        <mf-button block icon="database" label="Mongo Queries" @click="openSqlsMongo(index)"></mf-button>
      </v-col>
      <v-col v-if="server.integrator !== 'database_mongo_integrator'" cols="3">
        <mf-button block label="SQLs" icon="database" @click="openSql(index)"></mf-button>
      </v-col>
      <v-col cols="3">
        <mf-button label="Views" icon="table_view" block @click="openView(index)"></mf-button>
      </v-col>
      <v-col cols="3">
        <mf-button label="Filtros" icon="filter_alt" block @click="openFilter(index)"></mf-button>
      </v-col>
      <v-col cols="3">
        <mf-button block label="Variáveis" icon="variables" class="mx-2" @click="openVariable(index)"></mf-button>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="auto">
        <btn-card-actions class="pa-0 ml-0" :editable.sync="editable" :saving="saving" @save="saveEditManager(index)" @cancel="$emit('cancel')" />
      </v-col>
      <v-col cols="auto">
        <mf-button v-if="editable" class="my-2 ml-0" color="error" icon="storage" label="Remover este servidor" @click="saveDeleteManager(index)"> </mf-button>
      </v-col>
    </v-row>

    <!-- dialogs -->
    <sqls-dialog
      :is-hybrid="isHybrid"
      :query-keys="queryKeys"
      :is-deploying="isDeploying"
      :dialog="sqlsDialog"
      :account-id="accountId"
      :server.sync="selectedServer"
      @close="closeDialog"
    />
    <sqls-mongo-dialog
      :is-hybrid="isHybrid"
      :query-keys="queryKeys"
      :is-deploying="isDeploying"
      :dialog="sqlsMongoDialog"
      :account-id="accountId"
      :server.sync="selectedServer"
      @close="closeDialog"
    />
    <views-dialog :is-deploying="isDeploying" :dialog="viewsDialog" :account-id="accountId" :server.sync="selectedServer" @close="closeDialog" />
    <filters-dialog :is-deploying="isDeploying" :dialog="filtersDialog" :server.sync="selectedServer" @close="closeDialog" />
    <variables-dialog :is-deploying="isDeploying" :dialog="variablesDialog" :server.sync="selectedServer" @close="closeDialog" />

    <change-dialog :dialog.sync="changesDialogEdit" :message.sync="changesMessage" @proceed="editServerConnection(index)" />
    <change-dialog :dialog.sync="changesDialogDelete" :message.sync="changesMessage" @proceed="removeServer(index)" />
  </v-expansion-panel-content>
</template>

<script>
import { MUTATION_EDIT_CONNECTION_SERVER, MUTATION_REMOVE_SERVER_CONNECTION } from '@/modules/accounts/graphql'

export default {
  components: {
    SqlsDialog: () => import('@/modules/accounts/partials/SqlsDialog'),
    SqlsMongoDialog: () => import('@/modules/accounts/partials/SqlsMongoDialog'),
    ViewsDialog: () => import('@/modules/accounts/partials/ViewsDialog'),
    VariablesDialog: () => import('@/modules/accounts/partials/VariablesDialog'),
    FiltersDialog: () => import('@/modules/accounts/partials/FiltersDialog'),
    BtnCardActions: () => import('@/components/base/BtnCardActions'),
    ChangeDialog: () => import('@/components/base/ChangeDialog')
  },
  props: {
    accountId: {
      type: String,
      default: ''
    },
    servers: {
      type: Array,
      default: () => []
    },
    index: {
      type: Number,
      default: 0
    },
    server: {
      type: Object,
      default: () => ({})
    },
    erp: {
      type: Object,
      default: () => ({})
    },
    isDeploying: {
      type: Boolean,
      default: false
    },
    isHybrid: {
      type: Boolean,
      default: false
    },
    queryKeys: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    editable: false,
    saving: false,
    changesDialogEdit: false,
    changesDialogDelete: false,
    changesMessage: '',
    selectedServer: {},
    sqlsDialog: false,
    sqlsMongoDialog: false,
    viewsDialog: false,
    filtersDialog: false,
    variablesDialog: false
  }),
  methods: {
    async removeServer(index) {
      const variables = {
        id: this.accountId,
        index: +index
      }
      this.$setLogMessage(this.changesMessage)
      try {
        await this.$apollo.mutate({
          mutation: MUTATION_REMOVE_SERVER_CONNECTION,
          variables
        })
        this.servers.splice(index, 1)
        this.$alert({
          alert_message: 'Servidor removido com sucesso',
          alert_title: 'Sucesso!',
          alert_color: 'success',
          alert_icon: 'mdi-check-circle'
        })
        this.editable = false
      } catch (err) {
        console.log(err)
        this.$alert({
          alert_message: 'Falha ao remover connection',
          alert_title: 'Erro!',
          alert_color: 'error',
          alert_icon: 'mdi-close-circle'
        })
      }
    },
    async editServerConnection(index) {
      const serverIndex = +index
      const { connection } = this.servers[index]
      const variables = {
        id: this.accountId,
        server_index: serverIndex,
        host: connection.host,
        port: connection.port,
        password: connection.password,
        user: connection.user,
        database: connection.database,
        charset: connection.charset
      }
      this.$setLogMessage(this.changesMessage)
      try {
        this.saving = true
        await this.$apollo.mutate({
          mutation: MUTATION_EDIT_CONNECTION_SERVER,
          variables
        })
        this.$alert({
          alert_message: 'Conexão alterada com sucesso',
          alert_title: 'Sucesso!',
          alert_color: 'success',
          alert_icon: 'mdi-check-circle'
        })
      } catch (err) {
        console.log(err.message)
        this.$alert({
          alert_message: 'Falha ao editar conexão',
          alert_title: 'Erro!',
          alert_color: 'error',
          alert_icon: 'mdi-close-circle'
        })
      }
      this.saving = false
      this.editable = false
    },
    openSql(index) {
      this.selectedServer = this.servers[index]
      this.selectedServer.index = index
      this.selectedServer.accountId = this.accountId

      let erpServer
      if (this.selectedServer.integrator) {
        this.erp.import_configuration.map(server => {
          if (server.integrator === this.selectedServer.integrator) {
            erpServer = server
          }
        })
      } else {
        erpServer = this.erp.import_configuration[0]
      }

      this.selectedServer.erpServer = erpServer
      this.selectedServer.erpServer.acronym = this.erp?.acronym
      if (!this.selectedServer.configuration.sqls) this.selectedServer.configuration.sqls = {}

      this.sqlsDialog = true
    },
    openSqlsMongo(index) {
      this.selectedServer = this.servers[index]
      this.selectedServer.index = index
      this.sqlsMongoDialog = true
    },
    openView(index) {
      this.selectedServer = this.servers[index]
      this.selectedServer.index = index
      this.selectedServer.accountId = this.accountId
      if (!this.selectedServer.configuration.views) this.selectedServer.configuration.views = {}
      this.viewsDialog = true
    },
    openVariable(index) {
      this.selectedServer = this.servers[index]
      this.selectedServer.index = index
      this.selectedServer.accountId = this.accountId
      if (!this.selectedServer.configuration.variables) this.selectedServer.configuration.variables = {}
      this.variablesDialog = true
    },
    openFilter(index) {
      this.selectedServer = this.servers[index]
      this.selectedServer.index = index
      this.selectedServer.accountId = this.accountId
      if (!this.selectedServer.configuration.filters) this.selectedServer.configuration.filters = { units: [] }
      this.filtersDialog = true
    },
    closeDialog() {
      this.sqlsDialog = false
      this.viewsDialog = false
      this.filtersDialog = false
      this.variablesDialog = false
      this.sqlsMongoDialog = false
      this.selectedServer = {}
      this.$emit('refresh')
    },
    saveEditManager(index) {
      if (this.isDeploying) return this.editServerConnection(index)
      this.changesDialogEdit = true
    },
    saveDeleteManager(index) {
      if (this.isDeploying) return this.removeServer(index)
      this.changesDialogDelete = true
    }
  }
}
</script>
